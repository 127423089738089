@import '/sticky-banner.css';
@import './blocks/hero.css';
@import './blocks/four-feature-image.css';
@import './blocks/campaign-video.css';
@import './blocks/ingredients.css';
@import './blocks/faq-accordion.css';

@layer components {
  .block-content {
    @apply bg-cover bg-no-repeat;
  }
}
