@layer components {
  .bottom-links {
    & > ul {
      @apply flex flex-wrap justify-center;

      & > li {
        @apply relative leading-26 mmx-12;

        &:not(:last-child)::after {
          @apply absolute right-[-12px] top-1/2 -translate-y-2/4 text-black md:content-['|'];
        }

        &:not(:last-child):not(:first-child)::after {
          @apply font-bold content-['|'] md:font-normal;
        }

        &:first-child {
          @apply w-full text-center mmb-15 md:w-auto md:mmb-0;
        }

        & > a {
          @apply font-bold md:font-normal;
        }
      }
    }
    & > .footerCookieOptions {
      @apply flex justify-center mmb-20 md:hidden;
    }
  }

  .chatLinkContainer {
    & > .chatLink {
      @apply block md:hidden;
    }
  }

  .footer-column {
    & > ul {
      @apply xl:min-w-[270px];

      & > li {
        @apply leading-32 mmb-14 md:mmb-10;

        & > figure {
          @apply mmr-10 mmt-5;
        }

        &:first-child {
          @apply font-bold uppercase;
        }
      }
    }

    & > p {
      @apply leading-relaxed;

      &:first-child {
        @apply font-bold mmb-8;
      }

      &:nth-child(5) {
        @apply font-bold mmb-8 mmt-24;
      }
    }
  }
}

button.osano-cm-widget {
  display: none;
}

@media (min-width: 768px) {
  button.osano-cm-widget {
    display: block;
  }
}
