@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

@layer base {
  body {
    @apply text-base;
  }

  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 0 0% 100%;
    --muted-foreground: 0 0% 51%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 0 0% 67%;

    --primary: 179 51% 69%;
    --primary-foreground: 187 69% 85%;

    --secondary: 186 100% 27%;
    --secondary-foreground: 186 100% 27%;

    --accent: 32 83% 70%;
    --accent-foreground: 15 86% 66%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.3125rem;
  }
}
