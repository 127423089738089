@layer components {
  .custom-scroll,
  .home-custom-scroll {
    @apply mml-20 mmr-20;
  }
}

/* no scroll bar */
.no-scrollbar,
.link-no-scroll {
  scrollbar-width: none;
  @apply mb-0 pb-0;
}

.no-scrollbar::-webkit-scrollbar,
.link-no-scroll::-webkit-scrollbar {
  @apply hidden;
}

.custom-scroll,
.home-custom-scroll {
  @apply bg-orange-lighter;
}

/* no scroll bar */
.no-scrollbar,
.link-no-scroll {
  scrollbar-width: none;
  @apply mb-0 pb-0;
}

.no-scrollbar::-webkit-scrollbar,
.link-no-scroll::-webkit-scrollbar {
  @apply hidden;
}

.custom-scroll .bg-border {
  @apply bg-blue-turquoise;
}

.home-custom-scroll .bg-border {
  @apply bg-orange-salmon;
}

.custom-scroll .bg-border,
.home-custom-scroll .bg-border {
  @apply flex-none;
}

.slider-dark-scroll .bg-border {
  @apply bg-orange-lighter mmt-[-1px];
}

@media (min-width: 1760px) {
  .custom-scroll,
  .home-custom-scroll {
    margin-left: calc((100% - 1760px + 2.5rem) / 2);
    margin-right: calc((100% - 1760px + 2.5rem) / 2);
  }
}

@media (max-width: 767px) {
  .no-scrollbar::-webkit-scrollbar {
    @apply block;
  }

  .scroll-grid::-webkit-scrollbar-track {
    @apply mmx-0;
  }
}
