@layer components {
  .four-feature-image {
    h3 {
      @apply lg:max-ww-[33.333%]; /* one column width */
    }

    .four-feature-image--inner {
      @apply w-auto overflow-x-auto lg:w-full;
      @apply grid grid-cols-[repeat(4,_minmax(17.8125rem,_1fr))] grid-rows-[auto_auto] grid-gap-12;
      @apply lg:grid-cols-[1fr_1fr_1fr] lg:grid-rows-[1fr_auto_1fr_auto] lg:grid-gap-20;
    }

    .four-feature-image--media {
      @apply relative z-1 min-w-[17.875rem] overflow-hidden rounded-md ppb-[178%] hh-0 ww-full lg:min-w-0; /* custom aspect-ratio */
      @apply row-start-1 row-end-2;

      &-1 {
        @apply col-start-1 col-end-2;
        @apply lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-4;
      }
      &-2 {
        @apply col-start-2 col-end-3;
        @apply lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-4;
      }
      &-3 {
        @apply col-start-3 col-end-4;
        @apply lg:col-start-3 lg:col-end-4 lg:row-start-1 lg:row-end-2;
        @apply lg:h-auto lg:pb-0;
      }
      &-4 {
        @apply col-start-4 col-end-5;
        @apply lg:col-start-3 lg:col-end-4 lg:row-start-3 lg:row-end-4;
        @apply lg:h-auto lg:pb-0;
      }
    }

    .four-feature-image--title {
      @apply relative z-2;
      @apply row-start-2 row-end-3;

      &-1 {
        @apply col-start-1 col-end-2;
        @apply lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-4;
      }
      &-2 {
        @apply col-start-2 col-end-3;
        @apply lg:col-start-2 lg:col-end-3 lg:row-start-1 lg:row-end-4;
      }
      &-3 {
        @apply col-start-3 col-end-4;
        @apply lg:col-start-3 lg:col-end-4 lg:row-start-1 lg:row-end-2;
      }
      &-4 {
        @apply col-start-4 col-end-5;
        @apply lg:col-start-3 lg:col-end-4 lg:row-start-3 lg:row-end-4;
      }

      & > a,
      & > h4 {
        @apply lg:absolute lg:bottom-0 lg:left-0 lg:right-0 lg:z-2;
      }
    }

    .four-feature-image--caption {
      @apply row-start-3 row-end-4;

      &-1 {
        @apply col-start-1 col-end-2;
        @apply lg:col-start-1 lg:col-end-2 lg:row-start-4 lg:row-end-5;
      }
      &-2 {
        @apply col-start-2 col-end-3;
        @apply lg:col-start-2 lg:col-end-3 lg:row-start-4 lg:row-end-5;
      }
      &-3 {
        @apply col-start-3 col-end-4;
        @apply lg:col-start-3 lg:col-end-4 lg:row-start-2 lg:row-end-3;
      }
      &-4 {
        @apply col-start-4 col-end-5;
        @apply lg:col-start-3 lg:col-end-4 lg:row-start-4 lg:row-end-5;
      }
    }
  }
}
