:root {
  --gray-tundora: #414144;
}

@layer components {
  .form-control-wrap .form-control-input {
    @apply h-[75px] rounded-[5px] border-gray-silver text-22 text-gray-tundora ppl-20;
  }

  .form-control-wrap .form-input-label {
    @apply z-1 text-22 font-normal text-gray-tundora;
  }

  .form-control-wrap .show-pwd button {
    @apply bg-transparent hover:bg-transparent focus:bg-transparent;
  }

  .form-control-wrap .ref-code-btn {
    @apply block text-blue-turquoise;
  }

  .form-control-wrap .birthday-control {
    @apply mmt-12;
  }

  .form-control-wrap .valid-wrap {
    @apply z-1;
  }

  .form-control-wrap .drop-down-wrap {
    @apply mml-0 mmr-12 last:flex-1 last:mmr-0;
  }

  .form-control-wrap .reff-label {
    @apply text-22 font-light leading-[1.23] text-blue-turquoise;
  }

  .form-control-wrap .submit-btn {
    @apply h-[75px];
  }

  .form-control-wrap .custom-checkbox {
    @apply h-7 w-7 rounded-none border-2 border-solid border-gray-dark;
  }

  .form-control-wrap .show-pwd {
    @apply pp-0;
  }

  .form-control-wrap .checkbox-label {
    @apply mmt-0 ppt-4 !important;
  }

  .custom-control-wrap label {
    @apply z-1 text-22 font-normal text-gray-tundora;
  }

  .mandatory-icon {
    @apply text-22 font-extrabold not-italic text-red mml-4;
  }
  .mandatory-icon-small {
    @apply text-sm font-extrabold not-italic text-red mml-2;
  }

  .form-control-wrap input:focus + label:not(.checkbox-label):not([for='fileUploader_SUPPORT']),
  .form-control-wrap
    input:not(:placeholder-shown)
    ~ label:not(.checkbox-label):not([for='fileUploader_SUPPORT']):not(.upload-label),
  .form-control-wrap textarea:focus + label:not(.checkbox-label),
  .form-control-wrap textarea:not(:placeholder-shown) ~ label:not(.checkbox-label) {
    @apply text-base font-semibold text-gray-suva;
  }

  .form-control-wrap .valid-success input,
  .form-control-wrap .valid-error input {
    @apply ppr-[72px];
  }

  .show-pwd button {
    @apply pp-0;
  }

  .form-control-wrap .valid-error input,
  .form-control-wrap .valid-error textarea,
  .form-control-wrap .valid-error button {
    @apply border-red;
  }

  .form-control-wrap .valid-success input,
  .form-control-wrap .valid-success textarea,
  .form-control-wrap .valid-success button {
    @apply border-feedback-success;
  }

  .multi-icon-field.valid-success input,
  .multi-icon-field.valid-error input {
    @apply ppr-[120px];
  }

  .multi-icon-field .valid-wrap {
    @apply right-[85px];
  }

  .form-control-wrap .ref-code-btn {
    @apply h-auto;
  }

  .form-control-wrap input ~ label:-webkit-autofill ~ label {
    @apply text-gray-tundora !important;
  }

  .form-control-wrap input:-webkit-autofill,
  .form-control-wrap input:-webkit-autofill:hover,
  .form-control-wrap input:-webkit-autofill:focus,
  .form-control-wrap input:-webkit-autofill:active,
  .form-control-wrap textarea:-webkit-autofill,
  .form-control-wrap textarea:-webkit-autofill:hover,
  .form-control-wrap textarea:-webkit-autofill:focus,
  .form-control-wrap textarea:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--gray-tundora);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px transparent;
    font-size: 1.375rem;
  }

  .terms-wrapper a {
    @apply text-base font-bold text-blue-turquoise underline;
  }

  .terms-wrapper {
    @apply gap-[5px];
  }
}
