@layer components {
  .auth-bar {
    & > li {
      @apply relative mmx-12;

      &:not(:last-child)::after {
        @apply absolute right-[-12px] top-1/2 -translate-y-2/4 text-black md:content-['|'];
      }

      &:last-child {
        @apply mmr-0;
      }
    }
  }

  .mobile-navigation {
    & > li {
      @apply text-black;

      &:not(:last-child) {
        @apply border-b border-gray;
      }

      &:first-child {
        @apply border-t border-gray;
      }

      & > button,
      a {
        @apply flex w-full text-15 font-extrabold uppercase leading-20 ppx-20 ppy-14;
      }
    }
  }

  .submenu-navigation {
    & > li {
      @apply text-black;

      &:not(:last-child) {
        @apply border-b border-gray;
      }

      &:first-child {
        @apply border-t border-gray;

        & > button {
          @apply text-15 font-extrabold uppercase leading-20 ppy-15;
        }
      }

      & > button,
      a {
        @apply flex w-full items-center text-base font-medium ppy-14;
      }
    }
  }

  .active-header::after {
    @apply absolute bottom-1 w-full bg-orange-salmon content-[''] animate-in fade-in hh-4;
  }
  .userProfile {
    @apply relative;
  }
  .userProfile + .popBox:hover,
  .userProfile:hover + .popBox {
    @apply lgMenu:block;
  }
  .userProfile + .popBox {
    @apply absolute left-[-50px] top-[45px] z-[1] hidden w-[174px] translate-x-[-13%] translate-y-[-5%] rounded-[20px] bg-white p-[20px] shadow-[2px_2px_9px_0_rgba(0,0,0,0.25)];
  }
  .userProfile + .popBox a {
    @apply block text-center text-[14px] font-bold leading-[28px];
  }
  .tooltip__arrow {
    @apply absolute bottom-full left-[50%] h-[25px] w-[50px] translate-x-[-50%] overflow-hidden;
  }
  .tooltip__arrow::before {
    @apply absolute left-2/4 top-0 h-[20px] w-[20px] translate-x-[-50%] translate-y-[100%] rotate-[45deg] bg-white shadow-[1px_1px_20px_0_rgba(0,0,0,0.6)] content-[''];
  }
  .search-wrap {
    .search-icon-wrap {
      @apply flex h-auto mml-0 mmr-18 pp-0;
    }
  }
}
