@layer utilities {
  /* Full-height image/video */
  .media-full-height__wrap {
    @apply flex items-stretch justify-center overflow-hidden;
  }

  .media-full-height {
    @apply h-full w-auto max-w-none;

    &[data-nimg] {
      @apply !static !w-auto;
    }
  }

  /* Full-width image/video */
  .media-full-width__wrap {
    @apply flex items-center justify-stretch overflow-hidden;
  }
  .media-full-width {
    @apply h-auto max-h-none w-full;

    &[data-nimg] {
      @apply !static !h-auto;
    }
  }
}
