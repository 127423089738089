.search-input::placeholder {
  @apply italic text-gray-darker;
}

.search-input {
  @apply inline-flex w-full flex-1 rounded border border-solid border-gray-silver text-22 text-gray-darker mmb-16 pp-20 ppr-40 hh-75 focus-visible:outline-none md:w-4/5 md:mmb-0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30' fill='none'%3E%3Cpath d='m24.539 25.185-8.003-8.002a6.385 6.385 0 0 1-2.164 1.278 7.37 7.37 0 0 1-2.487.44c-1.992 0-3.677-.687-5.056-2.063-1.38-1.376-2.07-3.053-2.07-5.031 0-1.979.689-3.657 2.064-5.035 1.376-1.379 3.052-2.068 5.03-2.068 1.977 0 3.66.688 5.047 2.065 1.387 1.376 2.081 3.053 2.081 5.03 0 .858-.154 1.7-.462 2.526a6.75 6.75 0 0 1-1.288 2.163l8.01 7.971-.702.726zM11.878 17.94c1.722 0 3.176-.591 4.362-1.773s1.78-2.637 1.78-4.364c0-1.728-.594-3.182-1.78-4.364s-2.64-1.773-4.362-1.773c-1.726 0-3.184.59-4.373 1.773-1.19 1.182-1.784 2.636-1.784 4.364 0 1.727.595 3.182 1.784 4.364s2.647 1.773 4.373 1.773z' fill='%23000' stroke='%23000' stroke-width='.75'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 0.75rem;
  background-size: 24px 24px;
}
