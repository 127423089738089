@layer components {
  .faq-accordion {
    @apply bg-blue-lighter;

    .faq-accordion__item {
      @apply border-blue;
    }

    .faq-accordion__title {
      @apply flex items-center justify-start ppb-14 ppt-16 ppx-20;
      @apply text-left text-md font-semibold leading-normal lg:text-22 lg:leading-none lg:ppy-24;

      &:before {
        content: '\02212';
        @apply shrink-0  text-center text-24 font-extralight leading-tight text-[#007e8c];
        @apply rounded border-2 border-current;
        @apply pt-px mmr-20 hh-24 ww-24 lg:text-28 lg:ppt-5 lg:hh-34 lg:ww-34;
      }

      &[data-state='closed']:before {
        content: '\0002B';
      }

      svg {
        @apply hidden;
      }
    }

    .faq-accordion__content {
      @apply text-black ppb-12 ppx-20;
      @apply lg:ppb-36 lg:ppl-75 lg:ppr-32 lg:ppt-8;
      font-size: inherit;
      line-height: inherit;
    }
  }
}
