@layer components {
  /* Main container */
  .product-main {
    @apply lg:flex lg:justify-between;

    .product-gallery {
      @apply mmb-24 lg:mmb-0 lg:ww-[45%] xl:max-ww-[43rem] xl:ww-[40%];
    }

    .product-info {
      @apply lg:ww-[50%] xl:max-ww-[59.125rem] xl:ww-[55%];
    }
  }

  /* Main info */
  .product-info {
    .product-info-title {
      @apply text-[1.93rem] font-bold leading-none;
      @apply -order-3 lg:order-none;
    }

    .product-info-desc {
      @apply text-[1.15rem] font-light leading-26 mmb-28 mmt-12;

      a {
        text-decoration-thickness: 0.0625rem;
      }
    }

    .product-price-block {
      @apply flex items-center justify-between border-gray ppb-12 lg:border-t lg:ppl-5 lg:ppy-24;
    }

    .product-purchase-block {
      @apply lg:border-t lg:border-gray;
      @apply -order-1 lg:order-none;
    }

    .product-paypal-block {
      @apply mmy-25 min-hh-48 lg:ppx-15;
    }

    .product-quantity {
      input[type='number'] {
        -moz-appearance: textfield;
      }

      input[type='number']::-webkit-outer-spin-button,
      input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  /* Gallery */
  .product-gallery {
    @apply relative;

    .product-gallery-slide {
      @apply relative;
    }

    .product-gallery-item {
      @apply relative aspect-1/1 bg-white;
    }

    .product-gallery-caption {
      @apply absolute bottom-0 left-0 w-full bg-white/50 text-center text-black pp-14;
      @apply lg:static lg:bg-transparent lg:text-base lg:ppt-20 xl:ppt-28;

      i {
        @apply mx-auto block overflow-hidden max-hh-40 max-ww-560;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 2 lines restriction */
        -webkit-box-orient: vertical;
      }
    }
  }

  .product-gallery-width-nav {
    .product-gallery-item {
      @apply lg:mmb-70; /* leave space for slider navigation */
    }

    .slick-pager-custom {
      @apply lg:absolute lg:left-1/2 lg:top-0 lg:-translate-x-1/2 lg:mmt-[100%];
      @apply lg:ppy-14 xl:ppy-28;
    }
  }

  /* Info Tabs */
  .product-info-tabs {
    @apply mmt-10 lg:bg-gradient-blue-muted lg:mmt-40 lg:ppt-60;
  }

  .product-info-tab,
  .product-info-disclaimers {
    @apply lg:mx-auto lg:mmt-75 lg:max-ww-1430;
  }

  .product-tab-content {
    @apply mmb-36 mmt-8 [&:last-child]:mmb-24;
    @apply lg:mmb-50 [&:last-child]:lg:mmb-0;

    a {
      @apply font-bold text-blue-turquoise hover:opacity-75;
    }

    .product-tab__collection {
      @apply inline-block mmy-18 lg:mmb-28 [&:first-child]:mmt-0;
      @apply border-b border-blue text-lg lg:text-26;
    }

    .tab-block__disclaimer {
      @apply block leading-20 mmt-28 lg:text-base lg:mmt-36;
    }

    .faq-accordion {
      @apply -mx-[1.125rem] -mb-px mt-0;
    }
  }
  /* Label */
  .newLabel {
    @apply bg-black	text-base font-bold uppercase italic text-white;
  }
}
