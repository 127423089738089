@layer components {
  .campaign-video {
    .campaign-video--inner {
      @apply xl:flex xl:items-stretch xl:justify-items-stretch;
    }

    .campaign-video--video-wrap {
      @apply xl:basis-1/2;

      .video--controls {
        @apply 3xl:mmr-[calc(50vw-theme('container.screens.3xl')/2)];
      }
    }

    .campaign-video--image-wrap {
      @apply relative xl:basis-1/2;
    }

    .campaign-video--image {
      @apply relative overflow-hidden ppb-[138.5%] hh-0 ww-full; /* custom aspect-ratio in mobile */
      @apply lg:ppb-[56.25%];
      @apply xl:ppb-0 xl:hh-full;
    }

    .campaign-video--content {
      @apply absolute left-0 top-2/4 -translate-y-1/2;
      @apply flex flex-col items-start justify-center max-hh-full max-ww-full;
      @apply xl:max-ww-400 3xl:mml-[calc(50vw-theme('container.screens.3xl')/2)];
    }
  }
}
