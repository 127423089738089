@layer components {
  .hero-overlay {
    .hero-overlay--media {
      .video--controls {
        @apply pp-20 3xl:mmb-[2%];
      }
    }

    .hero-overlay--inner {
      @apply absolute left-0 top-1/2 -translate-y-2/4;
      @apply md:bottom-0 md:top-0 md:transform-none;
      @apply md:ppl-100 md:ppr-[7.25%];
    }

    .hero-overlay--content {
      @apply text-center ppx-20 ppy-36;
      @apply md:flex md:flex-col md:items-start md:justify-center md:text-left md:pp-20 md:hh-full;
    }

    &.enrollment-hero {
      .hero-overlay--media {
        @apply md:min-hh-150;
      }

      .hero-overlay--inner {
        @apply top-0 translate-y-0;
      }

      .hero-overlay--content {
        @apply text-left;

        h3 {
          @apply text-h3 text-black md:text-h2 lg:text-h1 3xl:text-h1-lg;
        }

        & > div {
          @apply text-black mmt-24;

          p + p {
            @apply mmt-16;
          }
        }

        & > a {
          @apply w-full mmt-40 md:w-auto;
        }
      }
    }
  }
}
