@layer utilities {
  .tab-trigger-button {
    @apply inline-flex items-center justify-center;
    @apply border-b-6 border-transparent ppt-4 ppx-0 hh-36 md:border-b-6 md:ppt-5 md:hh-40;
    @apply whitespace-nowrap text-base font-extrabold uppercase leading-none text-gray-silver md:text-md;
    @apply ring-offset-background transition-colors;
    @apply hover:border-gray-silver hover:text-gray-silver;
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1;
    @apply disabled:pointer-events-none disabled:opacity-50;
    @apply data-[state=active]:border-blue-turquoise data-[state=active]:text-blue-turquoise;
  }

  .tab-content {
    @apply ring-offset-background ppx-0 ppy-28 md:ppy-32;
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring;
  }
}
