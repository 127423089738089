@layer components {
  .border-button {
    @apply min-w-[193px] rounded border-2 border-white text-md capitalize text-white ppx-15 ppy-10;
  }

  .rotate-arrow .arrow-wrap {
    @apply rotate-180;
  }
}

:root {
  --orange: #f3855f;
  --lightorange: #fef8f1;
}

/* customize react slick slider library classes */
.slick-disabled {
  @apply opacity-0;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.custom-scroll-bar {
  scrollbar-width: auto;
  scrollbar-color: var(--orange) var(--darkBlue);
}

/* Chrome, Edge, and Safari */
.custom-scroll-bar::-webkit-scrollbar {
  @apply h-2 w-4 bg-orange-salmon;
}

.custom-scroll-bar::-webkit-scrollbar-track {
  @apply h-2 rounded-[10px] bg-orange-lighter;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
  @apply h-2 rounded-[10px] bg-orange-salmon;
}

.bg-blue-turquoise .slick-arrow {
  @apply top-[48%];
}

.bg-blue-turquoise .slick-arrow::before {
  content: '';
  @apply hidden;
}

@media (max-width: 767px) {
  .sticky-banner {
    @apply bottom-0;
  }
}
