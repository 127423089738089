/* CSS variables. */
@layer base {
  :root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.5rem;
    --PhoneInputCountrySelectArrow-width: 0.375rem;
    --PhoneInputCountrySelectArrow-marginLeft: var(--PhoneInputCountrySelect-marginRight);
    --PhoneInputCountrySelectArrow-borderWidth: 0.0625rem;
    --PhoneInputCountrySelectArrow-opacity: 1;
    --PhoneInputCountrySelectArrow-color: currentColor;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1rem;
    --PhoneInputCountryFlag-borderWidth: 0.0625rem;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
  }
}

@layer components {
  .PhoneInput {
    .PhoneInputInput {
      @apply min-w-0;
      text-indent: calc(
        var(--PhoneInputCountryFlag-aspectRatio) * var(--PhoneInputCountryFlag-height) +
          var(--PhoneInputCountrySelectArrow-width) + var(--PhoneInputCountrySelect-marginRight) * 2
      );
    }

    .PhoneInputCountry {
      @apply absolute left-0 top-[40%] z-5 flex items-center mml-20 mmt-[0.4375rem];
      margin-right: var(--PhoneInputCountrySelect-marginRight);
    }

    .PhoneInputCountrySelect {
      @apply absolute left-0 top-0 z-5 h-full w-full cursor-pointer border-0 opacity-0;

      &[disabled],
      &[readonly] {
        @apply cursor-default;
      }
    }

    .PhoneInputCountrySelectArrow {
      @apply block border-solid content-[""];
      width: var(--PhoneInputCountrySelectArrow-width);
      height: var(--PhoneInputCountrySelectArrow-width);
      margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
      border-color: var(--PhoneInputCountrySelectArrow-color);
      border-top-width: 0;
      border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      border-left-width: 0;
      border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
      transform: var(--PhoneInputCountrySelectArrow-transform);
      opacity: var(--PhoneInputCountrySelectArrow-opacity);
    }

    .PhoneInputCountryIcon {
      width: calc(var(--PhoneInputCountryFlag-height) * var(--PhoneInputCountryFlag-aspectRatio));
      height: var(--PhoneInputCountryFlag-height);

      &--square {
        width: var(--PhoneInputCountryFlag-height);
      }

      &--border {
        background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
        box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor),
          inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
            var(--PhoneInputCountryFlag-borderColor);
      }
    }

    .PhoneInputCountryIconImg {
      @apply block h-full w-full;
    }

    .PhoneInputInternationalIconPhone {
      opacity: var(--PhoneInputInternationalIconPhone-opacity);
    }

    .PhoneInputInternationalIconGlobe {
      opacity: var(--PhoneInputInternationalIconGlobe-opacity);
    }
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus);
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }
}
