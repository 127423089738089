@import 'tailwindcss/base';
@import './base/index.css';

@import './pages/index.css';

@import 'tailwindcss/components';
@import './components/index.css';

@import 'tailwindcss/utilities';
@import './utilities/index.css';
