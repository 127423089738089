@layer components {
  .custom-accordion > svg {
    @apply hidden;
  }

  .custom-accordion svg {
    @apply hh-inherit ww-inherit;
  }

  .custom-accordion .openState {
    @apply hidden;
  }

  .custom-accordion[data-state='open'] .openState {
    @apply block;
  }

  .custom-accordion[data-state='open'] .closeState {
    @apply hidden;
  }

  .custom-accordion .duration-200 {
    display: none;
  }

  /* image slider css */
  .center-image .slick-track .slick-slide {
    @apply flex max-h-[188px] items-center justify-center md:max-h-[923px];
  }

  .center-image .slick-track .slick-slide img,
  .thumbnail-wrapper .slick-slide img {
    @apply rounded-[5px];
  }

  .thumbnail-wrapper .slick-slider {
    @apply ppx-[50px] md:ppx-[145px];
  }

  .thumbnail-wrapper .slick-track {
    @apply flex justify-center gap-5 mmt-20 md:mmt-12;
  }

  .thumbnail-wrapper .slick-slide {
    @apply h-full min-ww-100 md:max-w-[270px];
  }

  .thumbnail-wrapper .slick-current img {
    @apply border-4 border-solid border-blue-turquoise;
  }

  .thumbnail-wrapper .slick-arrow::before {
    content: '';
    @apply hidden;
  }

  .thumbnail-wrapper .slick-arrow {
    @apply h-[48px] w-[48px];
  }

  .thumbnail-wrapper .slick-next {
    @apply right-0 md:right-[90px];
  }

  .thumbnail-wrapper .slick-prev {
    @apply left-0 md:left-[70px];
  }

  /* tab content padding */
  .custom-tab-content .tab-content {
    @apply pp-0 !important;
  }

  /* slider */

  .slider-main {
    .slick-slide {
      @apply text-center;
    }

    .slick-dots {
      @apply bottom-[-50px];

      li {
        @apply h-1 h-3 w-[25px] md:w-[50px];

        &.slick-active button {
          @apply bg-blue-turquoise;
        }

        button {
          @apply h-1 h-3 w-[25px] bg-gray-light pp-0 md:w-[50px];

          &::before {
            content: '';
            display: none;
          }
        }
      }
    }

    .slick-list {
      margin-bottom: 60px;
    }

    .slick-arrow::before {
      content: '';
      @apply hidden;
    }

    .slick-arrow {
      @apply h-[48px] w-[48px];
      bottom: -118px;
      top: unset;
    }

    .slick-prev {
      left: calc(50% - 100px);
    }

    .slick-next {
      right: calc(50% - 100px);
    }

    .slider__counter {
      width: 120px;
      text-align: center;
      margin: 0 auto;
    }
  }

  /* Slider Holiday */

  .slider-holiday {
    .slick-slide {
      @apply text-center;
    }

    .slick-dots {
      @apply bottom-[17px];

      li {
        @apply h-[14px] w-[14px] rounded-full;
        &.slick-active button {
          @apply bg-horizon-800;
        }

        button {
          @apply h-[14px] w-[14px] rounded-full border border-2 border-solid border-horizon-800 bg-white pp-0;
          &::before {
            content: '';
            display: none;
          }
        }
      }
    }

    .slick-list {
      margin-bottom: 60px;
    }

    .slick-arrow::before {
      content: '';
      @apply hidden;
    }

    .slick-arrow {
      @apply h-[24px] w-[24px] text-celesteal;
      bottom: 0;
      top: unset;
      z-index: 49;
    }

    .slick-prev {
      left: calc(50% - 100px);
    }

    .slick-next {
      right: calc(50% - 100px);
    }

    .slider__counter {
      width: 120px;
      text-align: center;
      margin: 0 auto;
    }

    /* Slider Holiday Control Color Themes */
    &.slider-holiday-controls,
    &.slider-holiday-controls-celesteal {
      .slick-arrow {
        @apply text-celesteal;
      }

      .slick-dots {
        li {
          &.slick-active button {
            @apply bg-horizon-800;
          }

          button {
            @apply border-horizon-800 bg-white;
          }
        }
      }
    }
    &.slider-holiday-controls-black {
      .slick-arrow {
        @apply text-grey;
      }

      .slick-dots {
        li {
          &.slick-active button {
            @apply bg-black;
          }

          button {
            @apply border-black bg-white;
          }
        }
      }
    }
    &.slider-holiday-controls-white {
      .slick-arrow {
        @apply text-grey-background;
      }

      .slick-dots {
        li {
          &.slick-active button {
            @apply bg-white;
          }

          button {
            @apply border-white bg-black;
          }
        }
      }
    }
  }
}
