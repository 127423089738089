@layer base {
  h1 {
    @apply text-h1;
  }

  h2 {
    @apply text-h2;
  }

  h3 {
    @apply text-h3;
  }
}

@layer utilities {
  .text-subheadline {
    @apply text-lg lg:text-24;
  }

  .text-marketing {
    @apply text-base leading-26 lg:text-22 lg:leading-34;
  }

  .link-turquoise-underline {
    @apply font-bold text-blue-turquoise underline hover:opacity-75;
  }

  /* ContentStack HTML-content styles */
  .cs-html {
    p,
    ul,
    ol {
      @apply mmy-16 [&:first-child]:mmt-0 [&:last-child]:mmb-0;
    }

    ul,
    ol {
      @apply list-outside mml-24;
    }
    ul {
      @apply list-disc;
    }
    ol {
      @apply list-decimal;
    }

    figure,
    img {
      @apply block;
      max-width: 100% !important;
      height: auto !important;
    }
  }

  .list-checkmarks {
    ul,
    ol {
      @apply list-none;

      li {
        @apply block mmb-10 [&:last-child]:mmb-0;
        @apply bg-[length:1.5rem] bg-[top_left] bg-no-repeat ppl-36 ppy-2;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6.504C7.487.879 4.566 2.588 2.682 5.135A10.323 10.323 0 0 0 1.619 6.9c-.643 1.311-1 2.542-1.136 3.917-.062.631-.053 1.915.017 2.521.311 2.654 1.499 5.048 3.446 6.944 1.773 1.726 3.909 2.792 6.374 3.179.571.09 2.236.125 2.865.061 3.969-.406 7.417-2.814 9.193-6.422.629-1.277.959-2.385 1.122-3.763.072-.604.082-1.924.02-2.537-.19-1.869-.798-3.565-1.857-5.18A11.575 11.575 0 0 0 13.166.481C12.594.424 11.155.437 10.6.504m2.714 2.322c1.971.241 3.77 1.138 5.246 2.614 1.526 1.526 2.388 3.294 2.643 5.42.07.586.059 1.821-.022 2.434a8.863 8.863 0 0 1-1.717 4.225c-.355.475-1.2 1.349-1.68 1.738-1.783 1.446-3.97 2.128-6.362 1.983-2.409-.146-4.393-1.061-6.084-2.805-1.6-1.651-2.446-3.57-2.575-5.845-.14-2.456.56-4.671 2.033-6.435.414-.496 1.216-1.266 1.686-1.619 1.927-1.447 4.245-2.027 6.832-1.71m.373 7.962-3.404 3.432-1.732-1.73-1.732-1.73-.829.83c-.457.457-.83.849-.83.87 0 .022 1.043 1.084 2.319 2.359l2.319 2.318.011.472.011.471 4.511-4.511 4.511-4.511L18 8.219a24.828 24.828 0 0 0-.876-.851c-.018-.006-1.565 1.533-3.437 3.42' fill='%23f3855f' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }
}
