@layer components {
  /* PLP container */
  .plp-container {
    @apply relative grid grid-cols-[50%_auto] mmt-28 grid-gap-20;
    @apply lg:grid-cols-[minmax(24%,_18.5rem)_auto] lg:items-start lg:mmt-24 lg:grid-gap-40;

    .plp-title {
      @apply col-span-2;
      @apply lg:col-span-1 lg:row-start-1;

      h1 {
        @apply text-24 font-bold text-black lg:text-h1;
      }
    }

    .plp-filters {
      @apply col-span-1;
      @apply lg:row-start-2;
      @apply grow;

      .tab-trigger-button {
        @apply border-b-5 max-hh-36;
      }
    }

    .plp-toolbar {
      @apply flex flex-col items-end grid-gap-y-20;
      @apply md:flex-row md:items-center md:justify-end;
      @apply col-start-2 row-start-2;
      @apply lg:col-span-1 lg:col-start-2 lg:row-start-1 lg:justify-between lg:self-end;
    }

    .plp-main {
      @apply col-span-2;
      @apply lg:col-span-1 lg:row-start-2 lg:max-ww-1140;
    }

    .plp-no-results {
      @apply text-center text-base text-black lg:text-22 lg:mmt-24;
    }

    &--hero-grid {
      .plp-hero__wrapper {
        @apply lg:col-span-1 lg:row-start-1 lg:row-end-3 lg:max-ww-1140;
      }

      .plp-filters {
        @apply lg:row-end-9;
      }

      .plp-toolbar {
        @apply row-start-3 lg:row-start-3;
      }

      .plp-main {
        @apply lg:row-start-4;
      }
    }
  }

  .plp-sort {
    @apply ppl-10 ppr-32 ppy-8 lg:ppl-16;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6' fill='none' %3E%3Cpath fill='%23007e8c' fill-rule='evenodd' clip-rule='evenodd' d='M7.71.907c.22.208.22.547 0 .756L3.938 5.25.164 1.663a.516.516 0 0 1 0-.756.583.583 0 0 1 .795 0l2.978 2.83L6.915.907a.583.583 0 0 1 .795 0z' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center right 0.75rem;
    background-size: 0.9375rem 0.9375rem;
  }

  .product-item {
    &__inner {
      @apply flex h-full flex-col text-left;
    }

    &__image {
      @apply relative aspect-1/1 w-full bg-white;
    }

    &__info {
      @apply text-black;
      @apply mmb-20 mmt-8 lg:mmb-30;
    }

    &__availability {
      @apply absolute left-0 right-0 top-1/2 z-1 -translate-y-1/2;
      @apply bg-blue-turquoise/90 ppx-15 ppy-8 md:ppy-18;
      @apply text-center text-13 font-semibold uppercase italic leading-normal text-white/90;
    }

    &__title {
      @apply line-clamp-4 text-base font-semibold lg:line-clamp-3 lg:text-22 lg:leading-[1.23];
    }

    &__label {
      @apply text-13 font-light mmt-5 lg:text-sm;
    }

    &__desc {
      @apply hidden;
    }

    &__price-wrapper {
      @apply mmb-20 lg:mmb-30;
    }

    &__price {
      @apply mt-auto text-28 font-semibold leading-26 text-black lg:text-h3 lg:font-semibold;
    }

    &__link {
      @apply w-full ppx-20;
    }
  }

  .products-listing {
    @apply grid ppy-0 grid-gap-20;

    &[data-view='grid'],
    &.view-mode-grid {
      @apply grid-cols-2 grid-gap-y-24 md:grid-cols-3 md:grid-gap-y-32 xl:grid-cols-4 xl:grid-gap-y-64;

      .product-subscribe-save {
        @apply lg:hidden;
      }
    }

    &[data-view='list'],
    &.view-mode-list {
      @apply grid-cols-1 grid-gap-y-24 xl:grid-gap-y-40;

      .product-item__inner {
        @apply grid grid-cols-[41%_auto] grid-rows-[auto_auto_auto] grid-gap-15;
        @apply xl:grid-cols-[15.625rem_auto_13.5rem] xl:grid-rows-1 xl:grid-gap-x-35;
        @apply border-b border-gray-light ppb-16 xl:ppb-24;
      }

      .product-item__image {
        @apply col-start-1 col-end-2 row-start-1 row-end-3;
        @apply xl:row-start-1 xl:row-end-2;
      }

      .product-item__info {
        @apply col-start-2 col-end-3 row-start-1 row-end-2;
        @apply lg:flex lg:flex-col lg:justify-start;
        @apply mm-0 lg:mmb-0;
      }

      .product-item__price-wrapper {
        @apply col-start-2 col-end-3 row-start-2 row-end-3 self-start mmy-0 lg:mmb-0;
        @apply xl:col-start-3 xl:col-end-4 xl:row-start-1 xl:row-end-2 xl:text-right xl:ppt-80;
      }

      .product-item__price {
        @apply mt-0;
      }

      .product-item__subscribe-save {
        @apply xl:items-end;
      }

      .product-item__link {
        @apply col-start-1 col-end-3 row-start-3 row-end-4;
        @apply xl:col-start-3 xl:col-end-4 xl:row-start-1 xl:row-end-2 xl:self-end;
      }

      .product-item__placeholder {
        @apply hidden;
      }

      .product-item__management {
        @apply col-start-1 col-end-3 row-start-3 row-end-4;
        @apply xl:col-start-3 xl:col-end-4 xl:row-start-1 xl:self-end;
      }

      .product-item__title {
        @apply xl:text-24 xl:leading-[1.12] xl:mmt-10;
      }

      .product-item__label {
        @apply lg:text-base;
      }

      .product-item__desc {
        @apply xl:block xl:text-base xl:font-light xl:leading-22 xl:mmt-20;
      }

      .product-subscribe-save {
        @apply lg:mt-auto lg:block lg:mmb-5;
      }
    }
  }
}
