/* Slider */
.slick-slider {
  @apply relative box-border block touch-pan-y select-none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  @apply relative m-0 block overflow-hidden p-0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  @apply cursor-[hand] cursor-pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  @apply relative left-0 top-0 mx-auto block after:clear-both;
}
.slick-track:before,
.slick-track:after {
  @apply table content-[''];
}
.slick-loading .slick-track {
  @apply invisible;
}
.slick-initialized .slick-track {
  @apply flex overflow-x-hidden;

  .slick-slide {
    @apply block h-auto shrink-0;

    > div {
      @apply h-full;
    }
  }
}
.slick-slide {
  @apply float-left hidden h-full min-h-[1px];
}
[dir='rtl'] .slick-slide {
  @apply float-right;
}
.slick-slide img {
  @apply block;
}
.slick-slide.slick-loading img {
  @apply hidden;
}
.slick-slide.dragging img {
  @apply pointer-events-none;
}
.slick-loading .slick-slide {
  @apply invisible;
}
.slick-vertical .slick-slide {
  @apply block h-auto border border-solid border-transparent;
}
.slick-arrow.slick-hidden {
  @apply hidden;
}
/* Custom pager */
.slick-pager-custom {
  @apply flex items-center justify-center ppy-4;

  button {
    @apply text-blue-turquoise ww-48 hover:text-blue-turquoise;
  }

  .slick-pager-progress {
    @apply text-center text-lg font-extralight text-black mmx-10 min-ww-100;
  }
}

.custom-slick-arrow {
  @apply before:hidden;
}
